import { Icon } from "@components/Icons"
import MyAccountModal from "@components/myAccountModal"
import TranslateWrapper from "@components/TranslateWrapper"
import translate from "@src/i18n"
import cx from "classnames"
import React from "react"

import styles from "./style.module.less"

const ErrorModal = (props) => {
  const { visible, description, handleClose, title } = props

  return (
    <MyAccountModal
      visible={visible}
      handleClose={handleClose}
      classname="errorModal"
    >
      <TranslateWrapper>
        <div className={cx(styles.mainContainer)}>
          <Icon name="AlertError" className={styles.iconError} />
          <span className={cx("title mb8", styles.form_title)}>
            {translate(title)}
          </span>
          <p
            className={cx(
              "f14 mb32 tc scorpion-main",
              styles.descriptionContainerError
            )}
          >
            <span>{description}</span>
          </p>
        </div>
      </TranslateWrapper>
    </MyAccountModal>
  )
}

export default ErrorModal
