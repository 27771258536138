import "./style.module.less"

import TranslateWrapper from "@components/TranslateWrapper"
import { Modal } from "antd"
import React from "react"

import SvgCloseModal from "../Icons/components/CloseModal"
import styles from "./style.module.less"

const MyAccountModal = (props) => {
  const {
    visible = false,
    handleClose = () => {},
    children = null,
    className = "",
  } = props

  return (
    <Modal
      visible={visible}
      className={`anonimatta-modal-account ${className}`}
      footer={false}
      onCancel={handleClose}
      closable={false}
      closeIcon={
        <button className={styles.buttonClose}>
          <SvgCloseModal />
        </button>
      }
      destroyOnClose={true}
    >
      <TranslateWrapper>{children}</TranslateWrapper>
    </Modal>
  )
}

export default MyAccountModal
