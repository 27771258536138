import { CustomModalErrors } from "@components/CustomModalErrors"
import CustomProTable from "@components/CustomProTable"
import { Icon } from "@components/Icons/index"
import { LayoutAdmin } from "@components/LayoutAdmin"
import { useStores } from "@hooks/use-stores"
import { Button, Col, Input, Modal } from "antd"
import dayjs from "dayjs"
import _get from "lodash/get"
import React, { useCallback, useEffect, useRef, useState } from "react"
import InputMask from "react-input-mask"

const PostAdminPage = () => {
  const [errors, setErrors] = useState([])
  const [isClient, setIsClient] = useState(false)
  const [data, setData] = useState([])
  const [totalData, setTotalData] = useState([])

  const actionRef = useRef(null)
  const { post } = useStores()

  const { findAllAdmin, deletePost } = post

  useEffect(() => {
    setIsClient(true)
  }, [])

  const handleFindAllPosts = useCallback(async (params) => {
    const response = await findAllAdmin(params)

    const newResponse = _get(response, "data", [])
    const total = _get(response, "total", [])

    setData(newResponse)
    setTotalData(total)
  }, [])

  const handleDeletePost = async (id) => {
    Modal.confirm({
      title: "Você tem certeza que deseja deletar esse item?",
      onOk: async () => {
        await deletePost(id)
        await handleFindAllPosts()
      },
      okType: "danger",
      okText: "Sim",
      cancelText: "Não",
    })
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "postId",
      className: "drag-visible",
      key: "postId",
      align: "center",
      width: "70px",
    },
    {
      title: "Texto do post",
      dataIndex: "postText",
      className: "drag-visible",
      key: "postText",
      align: "center",
    },
    {
      title: "Data de Publicaçao",
      dataIndex: "datePublication",
      key: "datePublication",
      hideInSearch: true,
      render: (date, record) => (
        <label className="pointer normal">
          {dayjs(date).isValid() ? dayjs(date).format("DD/MM/YYYY") : "-"}
        </label>
      ),
      renderFormItem: () => (
        <InputMask
          mask={"99/99/9999"}
          maskChar={null}
          placeholder="Informe o filtro"
        >
          {(inputProps) => <Input {...inputProps} />}
        </InputMask>
      ),
    },
    {
      title: "Username",
      dataIndex: "affiliatedUsername",
      key: "affiliatedUsername",
    },
    {
      title: "Ação",
      dataIndex: "action",
      render: (text, record) => {
        console.log("record", record)
        return (
          <Icon
            className="pointer normal"
            name="IcDelete"
            onClick={() => handleDeletePost(record.postId)}
          />
        )
      },
      hideInSearch: true,
    },
  ]

  if (!isClient) return null

  return (
    <LayoutAdmin
      title="Postagens"
      subTitle="Visualize e gerencie suas postagens."
    >
      <Col span={24}>
        <div className="creators-table">
          <CustomProTable
            actionRef={actionRef}
            headerTitle="Listagem de postagens"
            columns={columns}
            request={handleFindAllPosts}
            dataSource={data}
            toolBarRender={() => [
              <Button key="button" type="primary" className="button-creator">
                Cadastrar postagem
              </Button>,
            ]}
            pagination={{
              total: totalData,
              defaultCurrent: 1,
              defaultPageSize: 10,
              showTotal: (total) => {
                if (total > 0) {
                  return `Total ${total} ${total > 1 ? "itens" : "item"}`
                }

                return null
              },
            }}
          />

          {errors.length ? (
            <CustomModalErrors errors={errors} setErrors={setErrors} />
          ) : null}
        </div>
      </Col>
    </LayoutAdmin>
  )
}

export default PostAdminPage
